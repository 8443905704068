#supnav {
  display: none;
}

nav {
  display: none;
}

header {
  .container {
    padding: 0;
  }
}

article {
  padding: 0;
}

article p + h2,
article .row + h2,
article img + h2,
article ul + h2 {
  margin: 5pt 0;
}

article + section {
  display: none;
}

footer {
  a[href="https://www.linkedin.com/in/fredericzugaj"],
  a[href="https://twitter.com/FredericZugaj"] {
    display: none;
  }
  div + div {
    display: none;
  }
}
